import React from "react";
import {
  getSubscription,
  getSubscriptionPayments,
  saveSubscriptionPayment,
  getSubscriptionPaymentLink,
} from "~/actions/setupActions";
import {
  Row,
  Col,
  Divider,
  Space,
  Typography,
  Radio,
  Select as SelectAntD,
} from "antd";
import { PageTitle } from "~/components/Common/Text";
import Spin from "~/components/Common/Spin";
import {
  convertUtcDateToLocalDateTime,
  convertDateToUtcIso,
  translate,
  getUniqueCompanyName,
  getLoggedInUser,
} from "~/_utils";
import { TRANSLATION_NAMESPACE } from "~/_utils/consts";
import {
  stripePaymentLinksPKR,
  stripePaymentLinks,
  defaultPaymentProvider,
} from "~/config";
import { forceTokenRefresh } from "~/_auth/Auth";
import moment from "moment";
import Button from "~/components/Common/Button";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "~/components/Common/Table";
import { PartnerInfo } from "~/components/Common/Partner/PartnerInfo";
import queryString from "query-string";
import "./index.scss";
import {
  LockFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { isSubscriptionLinkedToPartner } from "~/_utils";

const { Text } = Typography;
const { Option } = SelectAntD;

const SubscriptionInfo = (props = {}) => {
  const { subscription = {} } = props;
  const {
    subscriptionType = "",
    subscriptionExpiryDateUtc = "",
  } = subscription;

  const subscriptionExpiryLocalDate = convertUtcDateToLocalDateTime(
    subscriptionExpiryDateUtc,
    true,
    false
  );
  const todayUtc = convertDateToUtcIso(new Date());
  const daysRemaining =
    moment(subscriptionExpiryDateUtc).diff(moment(todayUtc), "days") + 1;

  return (
    <>
      <Space direction="vertical" size={20}>
        <Text className="secondaryTitle">{"Subscription plan: "}</Text>
        <Text className="secondaryTitle">{"Expires on: "}</Text>
      </Space>
      <Space
        direction="vertical"
        size={20}
        style={{
          paddingLeft: "25px",
        }}
      >
        <Text className="secondaryText">{`${subscriptionType}`} </Text>
        <div>
          {subscriptionExpiryLocalDate !== "" && (
            <Text className="secondaryText">
              {`${subscriptionExpiryLocalDate}`}{" "}
            </Text>
          )}
          {daysRemaining >= 0 && (
            <Text className="secondaryText" style={{ paddingLeft: "10px" }}>
              {`(${daysRemaining} days left)`}{" "}
            </Text>
          )}
          {daysRemaining < 0 && (
            <Text
              className="secondaryText"
              style={{ paddingLeft: "10px", color: "#ff4d4f" }}
            >
              {`(Expired)`}{" "}
            </Text>
          )}
        </div>
      </Space>
    </>
  );
};

const SubscriptionToPay = (props = {}) => {
  const {
    selectedMonths = 1,
    subscriptionCurrencyCode,
    subscriptionAmount = 0,
    onMonthChange,
    onPaymentMethodChange,
    onPay,
    loading = false,
  } = props;
  const isInternationalSubscription =
    subscriptionCurrencyCode && subscriptionCurrencyCode.toUpperCase() === "USD"
      ? true
      : false;
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Space direction="vertical" size={20}>
          <Text className="secondaryTitle">{"Extend subscription: "}</Text>
          <Text className="secondaryTitle">{"Amount to pay: "}</Text>
        </Space>

        <Space direction="vertical" size={20} style={{ paddingLeft: "10px" }}>
          <SelectAntD
            allowClear={false}
            style={{ width: "120px" }}
            defaultValue={selectedMonths}
            onChange={onMonthChange}
          >
            <Option value={1}>1 month</Option>
            <Option value={6}>6 months</Option>
            <Option value={12}>12 months</Option>
          </SelectAntD>
          <Text className="secondaryText">
            {`${subscriptionCurrencyCode} ${selectedMonths *
              subscriptionAmount}`}
          </Text>
          <Radio.Group onChange={onPaymentMethodChange} defaultValue={2}>
            <Radio value={2}>Credit/Debit card</Radio>
            {isInternationalSubscription === false && (
              <>
                {/* <Radio value={4}>Easypaisa</Radio> */}
                <Radio value={5}>JazzCash</Radio>
              </>
            )}
          </Radio.Group>
          <Button
            disabled={subscriptionAmount <= 0}
            loading={loading}
            type="primary"
            style={{ width: "100%" }}
            onClick={onPay}
          >
            {`${translate("Pay", {
              namespace: TRANSLATION_NAMESPACE.Sell,
            })}`}
          </Button>
          <div style={{ color: "gray" }}>
            <LockFilled />
            <span style={{ marginLeft: "5px" }}>{`Secured payment by ${
              defaultPaymentProvider === 1 ? "Stripe" : "Paymob"
            }`}</span>
          </div>
        </Space>
      </Col>
    </Row>
  );
};

class Subscription extends React.Component {
  constructor(props) {
    super(props);

    const { subscription = {} } = props;
    this.state = {
      subscription: subscription,
      subscriptionPayments: [],
      formRef: null,
      paymentLink: (stripePaymentLinks[0] || {}).paymentLink,
      selectedMonths: 1,
      selectedPaymentMethod: 2,
    };

    this.handleSubmit = this.handleSubmit.bind();
    this.onFinishFailed = this.onFinishFailed.bind();
    this.setFormRef = this.setFormRef.bind();
    this.getSubscription = this.getSubscription.bind();
    this.getPaymentLink = this.getPaymentLink.bind();
    this.navigateToPayment = this.navigateToPayment.bind();
    this.saveSubscriptionPayment = this.saveSubscriptionPayment.bind();
    this.resetTableState = this.resetTableState.bind();
    this.handleFetch = this.handleFetch.bind();
    this.updateSubscriptionPayments = this.updateSubscriptionPayments.bind();

    this.columns = [
      {
        title: "Date",
        dataIndex: "paymentDate",
        key: "paymentDate",
      },

      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        sorter: false,
        render: (text, record) => `${record.currencyCode} ${record.amount}`,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => {
          if (record.status === 1) {
            return (
              <>
                <CheckCircleOutlined style={{ color: "green" }} /> Processed
              </>
            );
          }
          if (record.status === 1) {
            return (
              <>
                <ClockCircleOutlined style={{ color: "orange" }} /> In-Process
              </>
            );
          }
          if (record.status === 3) {
            return (
              <>
                <CloseCircleOutlined style={{ color: "red" }} /> Rejected
              </>
            );
          }
          return <></>;
        },
      },
    ];
  }
  componentDidMount() {
    const _this = this;
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const externalreferenceId = queryParams.sessionId || queryParams.order;
    const provider = parseInt(queryParams.provider) || 0;
    const success =
      (queryParams.success && queryParams.success.toLowerCase()) === "false"
        ? false
        : true;
    const merchantOrderId = queryParams.merchant_order_id;

    if (provider > 0) {
      _this.saveSubscriptionPayment({
        externalReference: externalreferenceId,
        paymentProvider: provider,
        success: success,
        reference: merchantOrderId,
        queryString: location.search.substring(1, location.search.length),
      });
    } else {
      _this.getSubscription();
      _this.handleFetch();
    }
  }

  resetTableState = (options, callback) => {
    this.setState(
      {
        pageInfo: {}, // always reset page to page 1
      },
      callback
    );
  };

  handleFetch = (options = {}) => {
    const _this = this;
    let { sort = {}, pageInfo = {} } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    this.setState({ paymentLoading: true });

    getSubscriptionPayments({ sort, pageInfo })
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateSubscriptionPayments(data);
        _this.setState({
          pageInfo: pageInfo,
          totalCount: totalCount,
          paymentLoading: false,
        });
      })
      .catch(function(err) {
        console.log(err);
        _this.setState({
          paymentLoading: false,
        });
      });
  };

  updateSubscriptionPayments = (data = []) => {
    const _this = this;
    const {
      subscriptionPayments: alreadyLoadedSubscriptionPayments = [],
      pageInfo = {},
    } = this.state;
    let subscriptionPayments =
      Object.keys(pageInfo).length === 0
        ? []
        : [...alreadyLoadedSubscriptionPayments]; //If page is reset start from empty list

    let startIndex = subscriptionPayments.length;
    data.forEach((subscriptionPayment, index) => {
      const {
        subscriptionPaymentId,
        amount,
        status,
        paymentDateTimeUtc,
        currencyCode,
      } = subscriptionPayment;

      subscriptionPayments.push({
        key: startIndex + index,
        amount: amount,
        status: status,
        currencyCode: currencyCode,
        paymentDate: convertUtcDateToLocalDateTime(paymentDateTimeUtc),
      });
    });

    _this.setState({
      subscriptionPayments: subscriptionPayments,
    });
  };

  saveSubscriptionPayment = (options = {}) => {
    const _this = this;
    const { saveSubscriptionPayment, history } = this.props;
    this.setState({
      loading: true,
    });
    saveSubscriptionPayment(options)
      .then(function(result) {
        history.replace({
          search: "",
        });
        _this.setState({
          loading: false,
        });
        forceTokenRefresh() //subscriptionExpiry will be updated in claims, so need to force token refresh
          .then(function() {
            _this.getSubscription();
            _this.handleFetch();
          })
          .catch(function(err) {
            console.log(err);

            _this.getSubscription();
            _this.handleFetch();
          });
      })
      .catch(function(err) {
        console.log(err);
        history.replace({
          search: "",
        });
        _this.setState({
          loading: false,
        });
        _this.getSubscription();
        _this.handleFetch();
      });
  };
  getPaymentLink = (options = {}) => {
    const {
      selectedMonths = 1,
      subscription = {},
      selectedPaymentMethod = 2,
    } = this.state;
    let paymentLink = "";

    const _this = this;

    return new Promise(function(resolve, reject) {
      //1: Stripe
      if (defaultPaymentProvider === 1) {
        const currencyCode = subscription.subscriptionCurrencyCode;
        if (currencyCode.toUpperCase() === "PKR") {
          const paymentLinkObj =
            stripePaymentLinksPKR.filter(
              (x) => x.months === selectedMonths
            )[0] || stripePaymentLinksPKR[0];
          paymentLink = (paymentLinkObj || {}).paymentLink;
        } else {
          const paymentLinkObj =
            stripePaymentLinks.filter((x) => x.months === selectedMonths)[0] ||
            stripePaymentLinks[0];
          paymentLink = (paymentLinkObj || {}).paymentLink;
        }
        if (!paymentLink) {
          return resolve({});
        }

        const updatedPaymentLink = `${paymentLink}?prefilled_email=${
          (getLoggedInUser() || {}).email
        }&client_reference_id=${getUniqueCompanyName()}_${selectedMonths}`;

        return resolve({ paymentLink: updatedPaymentLink });
      } else {
        //Paymob

        const { location } = _this.props;
        const queryParams = queryString.parse(location.search);
        const testModeCode = queryParams.testModeCode || "";

        getSubscriptionPaymentLink({
          numberOfMonths: selectedMonths,
          paymentMethod: selectedPaymentMethod,
          testModeCode: testModeCode,
        })
          .then(function(result = {}) {
            return resolve({ ...result.data });
          })
          .catch(function(err) {
            return reject(err);
          });
      }
    });
  };

  getSubscription = () => {
    const _this = this;

    this.setState({
      loading: true,
    });

    getSubscription()
      .then(function(result) {
        _this.setState({
          subscription: result.data,
          loading: false,
        });
      })
      .catch(function(err) {
        console.log(err);
        _this.setState({
          loading: false,
        });
      });
  };
  navigateToPayment = () => {
    const _this = this;

    _this.setState({
      loading: true,
    });

    this.getPaymentLink()
      .then(function(data = {}) {
        const paymentLink = data.paymentLink;

        if (!paymentLink) {
          _this.setState({
            loading: false,
          });
          return;
        }
        forceTokenRefresh()
          .then(function() {
            window.location = paymentLink;
          })
          .catch(function(err) {
            window.location = paymentLink;
          });
      })
      .catch(function(err) {
        console.log(err);
        _this.setState({
          loading: false,
        });
      });
  };
  handleSubmit = (values) => {
    console.log(values);
  };
  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  setFormRef = (element) => {
    const { formRef } = this.state;
    if (!formRef) {
      this.setState({ formRef: element });
    }
  };
  render() {
    const _this = this;
    const {
      subscription = {},
      loading = false,
      paymentLoading = false,
      selectedMonths = 1,
      subscriptionPayments = [],
      pageInfo = {},
    } = this.state;

    const columns = this.columns;
    const { subscriptionAmount, subscriptionCurrencyCode } = subscription;

    return (
      <div className="subscription-container">
        <Spin spinning={loading}>
          <PageTitle>Subscription</PageTitle>
          <Divider />
          <br />
          <Row>
            <Col xs={24}>
              <SubscriptionInfo subscription={subscription} />
            </Col>
            <Col xs={24} lg={16} xl={8}>
              <Divider style={{ marginTop: "25px", marginBottom: "25px" }} />

              {isSubscriptionLinkedToPartner() === true ? (
                <PartnerInfo headerText="Contact us to extend your subscription" />
              ) : (
                <SubscriptionToPay
                  selectedMonths={selectedMonths}
                  subscriptionAmount={subscriptionAmount}
                  subscriptionCurrencyCode={subscriptionCurrencyCode}
                  onMonthChange={(value) => {
                    _this.setState({
                      selectedMonths: value,
                    });
                  }}
                  onPaymentMethodChange={(e) => {
                    _this.setState({ selectedPaymentMethod: e.target.value });
                  }}
                  onPay={this.navigateToPayment}
                  loading={loading}
                />
              )}

              <Divider style={{ marginTop: "25px", marginBottom: "25px" }} />
            </Col>
          </Row>
          {isSubscriptionLinkedToPartner() === false && (
            <Row>
              <Col xs={24} lg={16} xl={8}>
                <Text className="secondaryTitle">{"Payment history "}</Text>
                <br />
                <Table
                  loading={paymentLoading}
                  columns={columns}
                  dataSource={subscriptionPayments}
                  hasNextPage={pageInfo.hasNextPage}
                  onPageChange={this.handleFetch}
                />
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { saveSubscriptionPayment })(
  withRouter(withNamespaces()(Subscription))
);
