import React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Divider } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import Button from "~/components/Common/Button";
import { PageTitle } from "~/components/Common/Text";
import Table from "~/components/Common/Table";
import { getUsers } from "~/actions/userActions";
import { constructUrl } from "~/config";
import { getLoggedInUser } from "~/_utils";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filterValues: props.filterValues,
    };

    this.resetTableState = this.resetTableState.bind();
    this.handleFetch = this.handleFetch.bind();
    this.updateData = this.updateData.bind();
    this.handleTableChange = this.handleTableChange.bind();

    this.columns = [
      {
        title: "Display name",
        dataIndex: "displayName",
        key: "displayName",
        fixed: "left",
        sorter: false,
      },
      {
        title: "Username",
        dataIndex: "userName",
        key: "userName",
        sorter: false,
      },
      {
        title: "Role",
        dataIndex: "roleTitle",
        key: "roleTitle",
        sorter: false,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: false,
      },
      {
        title: "",
        dataIndex: "",
        key: "actions",
        width: 85,
        render: (text, record) => (
          <Row>
            <Col xs={12}>
              <Button
                type="link"
                icon={<EditTwoTone />}
                onClick={() =>
                  this.onEdit({
                    userId: record.userId,
                    externalId: record.externalId,
                  })
                }
              />
            </Col>
          </Row>
        ),
      },
    ];
  }

  componentDidMount() {
    this.handleFetch();
  }
  onEdit = ({ userId, externalId }) => {
    const { history, match } = this.props;

    const currentLoggedInUser = getLoggedInUser() || {};
    const isCurrentLoggedInUser = currentLoggedInUser.uid === externalId;

    if (isCurrentLoggedInUser === true) {
      history.push(
        constructUrl({
          match,
          to: `/user/userProfile`,
          isNested: false,
        })
      );
    } else {
      history.push(
        constructUrl({
          match,
          to: `/user?userId=${userId}`,
          isNested: true,
        })
      );
    }
  };

  resetTableState = (options, callback) => {
    const {
      sort = this.state.sort,
      filterValues = this.state.filterValues,
    } = options;
    this.setState(
      {
        sort: sort || {},
        pageInfo: {}, // always reset page to page 1
        filterValues: filterValues || {},
      },
      callback
    );
  };

  handleFetch = (options = {}) => {
    const _this = this;

    let { filterValues = {}, sort = {}, pageInfo = {} } = this.state;
    if (pageInfo.hasNextPage === false) {
      return;
    }

    this.setState({ loading: true });
    getUsers({ filterValues, sort, pageInfo })
      .then(function(result) {
        const { data, totalCount, pageInfo = {} } = result;
        _this.updateData(data);
        _this.setState({
          loading: false,
          pageInfo: pageInfo,
          totalCount: totalCount,
        });
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  updateData = (data = []) => {
    const _this = this;
    const { users: alreadyLoadedUsers = [], pageInfo = {} } = this.state;
    let users =
      Object.keys(pageInfo).length === 0 ? [] : [...alreadyLoadedUsers]; //If page is reset start from empty list

    const startIndex = users.length;
    ////
    data.forEach((user, index) => {
      users.push({
        key: startIndex + index,
        ...user,
      });
    });

    _this.setState({
      users: users,
    });
  };

  handleTableChange = (pagination, filters, sorter = {}) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    const sort = JSON.parse(`{"${sortField}": "${sortOrder}"}`);

    this.resetTableState({ sort: sort }, this.handleFetch);
  };

  render() {
    const columns = this.columns;
    const { users = [], pageInfo = {} } = this.state;

    return (
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <PageTitle>Users</PageTitle>
            </Col>
            <Divider />
            <br />
          </Row>
        </Col>

        <Col xs={24}>
          <Divider />

          <Table
            loading={this.state.loading}
            columns={columns}
            onChange={this.handleTableChange}
            dataSource={users}
            hasNextPage={pageInfo.hasNextPage}
            onPageChange={this.handleFetch}
          />
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withNamespaces()(Users)));
