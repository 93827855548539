export const graphQlEndpoint = "https://cloudwinkel.azurewebsites.net/graphql/";
//export const graphQlEndpoint = "https://localhost:44302/graphql/";
//export const graphQlEndpoint = "http://localhost:5000/graphql/";

export const version = "1.0.9";
export const baseUrlPath = "/:locale(en|nl|it)";
export const defaultLocale = "en";
export const defaultBaseUrl = `/${defaultLocale}`;
export const defaultCurrency = "Rs ";
export const sellBucketShowPriceIncTax = true;
export const dbProducts = "cloudRetail-products";
export const dbCustomers = "cloudRetail-customers";
export const dbSales = "cloudRetail-sales";
export const urlForPollingOnline = "https://ipv4.icanhazip.com";
export const DateFormat = "DD-MM-YYYY";
export const DateMonthFormat = "MM-YYYY";
export const DateTimeFormat = `${DateFormat} HH:mm:ss`;
export const HelpPageUrl = "https://www.cloudwinkel.com/help/";
export const reCaptchaKey = "6LeIqssfAAAAAMF2hn_jriebfPzUcMaWVCIwK2Z0";

export const defaultPaymentProvider = 2; //1: stripe, 2: paymob
export const stripePaymentLinksPKR = [
  {
    months: 1,
    paymentLink: "https://buy.stripe.com/test_5kAbJE6zqcXxcRW8wz",
  },
  {
    months: 6,
    paymentLink: "https://buy.stripe.com/test_cN2150cXOcXxdW09AE",
  },
  {
    months: 12,
    paymentLink: "https://buy.stripe.com/test_dR6cNIe1S1eP4lqdQW",
  },
];

export const stripePaymentLinks = [
  {
    months: 1,
    paymentLink: "https://buy.stripe.com/test_14k294ga0bTtf04008",
  },
  {
    months: 6,
    paymentLink: "https://buy.stripe.com/test_cN21506zq7Dd3hm8wG",
  },
  {
    months: 12,
    paymentLink: "https://buy.stripe.com/test_9AQ4hc7Du4r1aJO4gr",
  },
];

export const productsIndex = ["name", "variant", "barcode"];
export const customersIndex = ["name"];
export const sellBucketStorageName = "sellBucket";

export const defaultPageSize = 12;

export const constructUrl = ({ match, to, isNested }) => {
  return isNested === true //add sub path to current component matched url
    ? `${match.url}${to}`
    : `/${match.params.locale}${to}`;
};
