import React from "react";
import "antd/dist/antd.css";
import "./App.scss";
import MainLayout from "./layouts/MainLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import PrivateLayoutRoute from "./layouts/PrivateLayoutRoute";
import PublicLayoutRoute from "./layouts/PublicLayoutRoute";
import Offline from "~/components/Offline";
import LoginForm from "./pages/Login";
import SignupForm from "./pages/Signup";
import ForgotPasswordForm from "./pages/ForgotPassword";
import ResetPasswordForm from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import Sell from "./pages/Sell";
import Pay from "./pages/Sell/Pay";
import History from "./pages/Sell/History";
import Purchase from "./pages/Purchases/Purchase";
import Purchases from "./pages/Purchases";
import QuickKeysSetup from "./pages/QuickKeysSetup";
import Customers from "./pages/Customers";
import Customer from "./pages/Customers/Customer";
import CustomerPayments from "./pages/Customers/CustomerPayments";
import Products from "./pages/Products";
import Product from "./pages/Products/Product";
import Suppliers from "./pages/Suppliers";
import Supplier from "./pages/Suppliers/Supplier";
import Categories from "./pages/Products/Categories";
import Brands from "./pages/Products/Brands";
import SalesTaxes from "./pages/Setup/SalesTaxes";
import Settings from "./pages/Setup/Settings";
import UserProfile from "./pages/Users/UserProfile";
import User from "./pages/Users/User";
import Users from "./pages/Users";
import Receivables from "./pages/Reports/Receivables";
import ReceivablesDetails from "./pages/Reports/ReceivablesDetails";
import TaxPayables from "./pages/Reports/TaxPayables";
import StockDetails from "./pages/Reports/StockDetails";
import SaleDetails from "./pages/Reports/SaleDetails";
import Subscription from "./pages/Subscription";
import { baseUrlPath, defaultBaseUrl } from "./config";
import Page404 from "./pages/Exception/Page404";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AuthContext } from "~/_auth/Auth";
import {
  SESSIONSTORAGE,
  SCOPE,
  PERMISSION,
  SUBSCRIPTION_TYPE,
} from "~/_utils/consts";

import {
  startBackgroundSaleSync,
  stopBackgroundSaleSync,
  durationSinceLastSaleSyncRun,
} from "~/actions/sellActions";
import { purgeSaleDb } from "~/_offline/offlineSales";
import { onlineStateChange } from "~/actions/offlineActions";

const EmptyComponent = () => {
  return <></>;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOnline: this.props.isOnline };
    const { location, history } = this.props;

    if (location.pathname === "/") {
      history.replace(defaultBaseUrl);
    }
  }
  componentDidMount() {
    purgeSaleDb();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isOnline = true, onlineStateChange } = nextProps;
    const { isOnline: isOnlineFromState = true } = prevState;

    let isSaleSyncInProgress = sessionStorage.getItem(
      SESSIONSTORAGE.isSellWorkerInProgress
    );

    isSaleSyncInProgress =
      isSaleSyncInProgress === true || isSaleSyncInProgress === "true";

    console.log("App > getDerivedStateFromProps");
    console.log("Is sale sync In-progress: ", isSaleSyncInProgress);
    console.log(
      "Duration since last sale sync (min): ",
      durationSinceLastSaleSyncRun() / 60
    );
    const isUserAuthenticated =
      App.contextType._currentValue.currentUser.isAuthenticated === true;

    console.log("isUserAuthenticated:", isUserAuthenticated);

    if (
      isUserAuthenticated === true &&
      isSaleSyncInProgress === false &&
      isOnline === true
    ) {
      console.log("starting sale sync bg-job");
      startBackgroundSaleSync();
    } else if (
      isUserAuthenticated === true &&
      isSaleSyncInProgress === true &&
      isOnline === true &&
      durationSinceLastSaleSyncRun() > 300 //make it more robust: if last job run is more than 5-min (300 sec) ago then restart the job (might have crashed that cause bg job to stop)
    ) {
      console.log("sale sync bg-job was stopped , restarting.");
      startBackgroundSaleSync({ forceStart: true });
    }

    onlineStateChange && onlineStateChange({ isOnline: isOnline });

    if (isOnlineFromState !== isOnline) {
      return { isOnline: isOnline };
    }

    return null;
  }

  render() {
    const { isOnline = true } = this.state;

    return (
      <Switch>
        <PublicLayoutRoute
          path={`${baseUrlPath}/Login`}
          component={LoginForm}
          layout={EmptyLayout}
        />
        <PublicLayoutRoute
          path={`${baseUrlPath}/Signup`}
          component={SignupForm}
          layout={EmptyLayout}
        />
        <PublicLayoutRoute
          path={`${baseUrlPath}/ForgotPassword`}
          component={ForgotPasswordForm}
          layout={EmptyLayout}
        />
        <PublicLayoutRoute
          path={`${baseUrlPath}/ResetPassword`}
          component={ResetPasswordForm}
          layout={EmptyLayout}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Dashboard`}
          component={Dashboard}
          layout={MainLayout}
          scope={SCOPE.Dashboard}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/`}
          component={Dashboard}
          layout={MainLayout}
          scope={SCOPE.Dashboard}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Sell`}
          component={Sell}
          layout={MainLayout}
          scope={SCOPE.Sell}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Sell/QuickKeysSetup`}
          component={QuickKeysSetup}
          layout={MainLayout}
          scope={SCOPE.SellQuickKeysSetup}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Sell/Pay`}
          component={Pay}
          layout={MainLayout}
          scope={SCOPE.Sell}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Sell/History`}
          component={History}
          layout={MainLayout}
          scope={SCOPE.Sell}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Purchase`}
          component={Purchase}
          layout={MainLayout}
          scope={SCOPE.Purchase}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Purchase/Overview`}
          component={Purchases}
          layout={MainLayout}
          scope={SCOPE.Purchase}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Customers`}
          component={Customers}
          layout={MainLayout}
          scope={SCOPE.Customers}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Customers/Customer`}
          component={Customer}
          layout={MainLayout}
          scope={SCOPE.Customers}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Customers/Payments`}
          component={CustomerPayments}
          layout={MainLayout}
          scope={SCOPE.Customers}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Products`}
          component={Products}
          layout={MainLayout}
          scope={SCOPE.Products}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Products/Product`}
          component={Product}
          layout={MainLayout}
          scope={SCOPE.Products}
          permission={PERMISSION.Create}
        />

        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Suppliers`}
          component={Suppliers}
          layout={MainLayout}
          scope={SCOPE.Suppliers}
          permission={PERMISSION.Read}
        />

        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Suppliers/Supplier`}
          component={Supplier}
          layout={MainLayout}
          scope={SCOPE.Suppliers}
          permission={PERMISSION.Create}
        />

        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Products/Categories`}
          component={Categories}
          layout={MainLayout}
          scope={SCOPE.Categories}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Products/Brands`}
          component={Brands}
          layout={MainLayout}
          scope={SCOPE.Brands}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Reports/Receivables`}
          component={Receivables}
          layout={MainLayout}
          scope={SCOPE.ReportsReceivables}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Reports/ReceivablesDetails`}
          component={ReceivablesDetails}
          layout={MainLayout}
          scope={SCOPE.ReportsReceivables}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Reports/TaxPayables`}
          component={TaxPayables}
          layout={MainLayout}
          scope={SCOPE.ReportsTaxPayables}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Reports/SaleDetails`}
          component={SaleDetails}
          layout={MainLayout}
          scope={SCOPE.ReportsSales}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Reports/StockDetails`}
          component={StockDetails}
          layout={MainLayout}
          scope={SCOPE.ReportsInventory}
          permission={PERMISSION.Read}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Setup/SalesTaxes`}
          component={SalesTaxes}
          layout={MainLayout}
          scope={SCOPE.SetupSalesTaxes}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Setup/Settings`}
          component={Settings}
          layout={MainLayout}
          scope={SCOPE.SetupSettings}
          permission={PERMISSION.Create}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Users`}
          component={Users}
          layout={MainLayout}
          scope={SCOPE.Users}
          permission={PERMISSION.Read}
          subscriptionType={SUBSCRIPTION_TYPE.Standard}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Users/User`}
          component={User}
          layout={MainLayout}
          scope={SCOPE.Users}
          permission={PERMISSION.Create}
          subscriptionType={SUBSCRIPTION_TYPE.Standard}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/User/UserProfile`}
          component={UserProfile}
          layout={MainLayout}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}/Subscription`}
          component={Subscription}
          layout={MainLayout}
        />
        <PrivateLayoutRoute
          exact
          path={`${baseUrlPath}`}
          component={EmptyComponent}
          layout={MainLayout}
        />
        <Route component={Page404} />
      </Switch>
    );
  }
}

App.contextType = AuthContext;

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, {
    onlineStateChange,
  })(App)
);
