import React from "react";
import { Row, Col, Typography } from "antd";
import { getLocalCompanySettings } from "~/_utils";
import Sendicon from "~/assets/sendIcon.png";

const { Text } = Typography;

const EnuVisionContactInfo = () => {
  return (
    <div style={{ paddingTop: "10px" }}>
      <Text>
        Email : info@enuvision.com
        <br />
        Phone : +19723527581
        <br />
        Phone : +923214181282
      </Text>
    </div>
  );
};

const CloudWinkelContactInfo = () => {
  return (
    <div style={{ paddingTop: "10px" }}>
      <Text>Email : info@cloudwinkel.com</Text>
    </div>
  );
};

export const PartnerInfo = (props = {}) => {
  const company = getLocalCompanySettings() || {};
  const reference = company.reference;
  const { headerText = "Contact information :" } = props;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} style={{ width: "130px" }}>
          <img src={Sendicon} style={{ width: "109px" }} alt="contact" />
        </Col>
        <Col>
          <Text style={{ fontWeight: "bold" }}>{headerText}</Text>
          <br />
          {(() => {
            switch (reference) {
              case "enuvision":
                return <EnuVisionContactInfo />;
              default:
                return <CloudWinkelContactInfo />;
            }
          })()}
        </Col>
      </Row>
    </>
  );
};
